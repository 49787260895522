import { Button } from "react-bootstrap"
import styles from './ExternalStatsButton.module.css';


export const ExternalStatsButton = ({ matchId, statsUrl } : {matchId: string, statsUrl: string}) => {
  let className = styles.hltvButton;
  let label = "hltv";

  if(matchId.endsWith('esea-league')) {
    className = styles.eseaButton;
    label = 'faceit';
  } else if(matchId.includes('user-upload') && statsUrl.length > 0) {
    className = styles.userUploadButton;
    label = 'info';
  } else if(matchId.includes('user-upload') && statsUrl.length === 0) {
    className = styles.noStatsButton;
    label = 'n/a';
  } else if(statsUrl.length === 0) {
    className = styles.noStatsButton;
    label = 'n/a';
  }

  return <Button
    variant={'outline-primary'}
    className={`d-flex justify-content-center align-items-center ${className}`}
    style={{paddingTop: 4, paddingBottom: 5}}
    onClick={() => window.open(statsUrl)}>
      <i className="bi bi-box-arrow-up-right"></i>
  </Button>
}
